import React from 'react';
import Page from '../views/Dealership/Features';

export default ({location}) => <Page location={location} seo={{
  title: "Benyt en unik løsning til bilforhandlere i Danmark",
  lang: 'da-DK',
  description: 'En løsning til bilforhandlere, der forenkler køretøjs- og kunderelaterede opgaver. Tjeneste til fjernmonitorering af køretøjsdata og proaktiv kundehåndtering.',
  meta: [{
    name: 'keywords',
    content: 'Løsning til bilforhandlere'
  }]
}}/>